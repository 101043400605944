import moment from 'moment';
import Parse from 'parse';
import { SessionStatus } from '.';
import { ClientCancellationReason } from '../../enums/ClientCancellationReason';
import { GoalModel } from '../Goal';
import { BaseSessionModel } from './BaseSession';
import { SupportSessionRequestModel } from './SupportSessionRequest';

export class SupportSessionModel extends BaseSessionModel {


  static new(): SupportSessionModel {
    return new SupportSessionModel(new (Parse.Object.extend('SupportSession'))());
  }

  // Override methods
  status(): SessionStatus {
    if (this.isNeedsFinalisation()) {
      return SessionStatus.needsFinalisation;
    }

    if (this.isNotAccepted()) {
      return SessionStatus.notAccepted;
    }

    return this.object.get('status') || 0;
  }

  // Bespoke to SupportSession
  scheduledStart(): Date {
    return this.object.get('scheduledStartDate');
  }

  scheduledEnd(): Date {
    return this.object.get('scheduledEndDate');
  }

  cancelledDate(): Date | undefined {
    return this.object.get('cancelledAt');
  }

  cancellationReason(): string | undefined {
    return this.object.get('cancellationReason');
  }

  frequency(): string | undefined {
    const freq = this.object.get('request')?.get('frequency');
    return [undefined, 'Weekly', 'Monthly'][freq];
  }

  isNeedsFinalisation(): boolean {
    const endDateTime = this.object.get('scheduledEndDate');
    if (moment(endDateTime).isBefore(moment()) && this.object.get('status') === SessionStatus.confirmed) {
      return true;
    }
    return false;
  }

  loggedStartDate(): Date | undefined {
    return this.object.get('loggedStartDate');
  }

  loggedEndDate(): Date | undefined {
    return this.object.get('loggedEndDate');
  }

  loggedDuration(): number {
    const endDate = this.loggedEndDate();
    const startDate = this.loggedStartDate();
    if (!endDate || !startDate) {
      return 0;
    }
    return (endDate.getTime() - startDate.getTime()) / 1000.0 / 60.0 / 60.0;
  }

  loggedDurationScheduled(): number {
    const endDate = this.scheduledEnd();
    const startDate = this.scheduledStart();
    if (!endDate || !startDate) {
      return 0;
    }
    return Math.abs((endDate.getTime() - startDate.getTime()) / 1000.0 / 60.0 / 60.0);
  }

  setLoggedEndDate(loggedEndDate: Date): void {
    this.object.set('loggedEndDate', loggedEndDate);
  }

  setLoggedStartDate(loggedStartDate: Date): void {
    this.object.set('loggedStartDate', loggedStartDate);
  }

  finalisedAt(): Date | undefined {
    return this.object.get('finalisedAt');
  }

  loggedTransport(): number {
    return this.object.get('loggedTransportKm') || 0;
  }

  setLoggedTransport(transport: number): void {
    this.object.set('loggedTransportKm', transport);
  }

  loggedParking(): number {
    return this.object.get('loggedTransportRelatedCosts') || 0;
  }

  setStatus(value: SessionStatus): void {
    this.object.set('status', value);
  }

  setLoggedParking(parking: number): void { this.object.set('loggedTransportRelatedCosts', parking); }

  setCancellationReason(value: ClientCancellationReason): void {
    this.object.set('cancellationReason', value);
  }

  setCancelledDate(value: Date | undefined): void {
    this.object.set('cancelledAt', value);
  }

  isNotAccepted(): boolean {
    const startDateTime = this.object.get('scheduledStartDate');
    if (moment(startDateTime).isBefore(moment()) && [SessionStatus.pendingClientApproval, SessionStatus.pendingWorkerApproval].includes(this.object.get('status'))) {
      return true;
    }
    return false;
  }

  isCancelledOrDeclined(): boolean {
    return [SessionStatus.cancelledByAdmin, SessionStatus.cancelledByClient, SessionStatus.cancelledByWorker, SessionStatus.declined, SessionStatus.declinedByClient, SessionStatus.noShow]
      .includes(this.object.get('status'));
  }

  async request(): Promise<SupportSessionRequestModel | undefined> {
    const pointer = this.object.get('request');
    if (!pointer) { return; }
    const request = await pointer.fetch();
    return new SupportSessionRequestModel(request);
  }

  requestSync(): SupportSessionRequestModel | undefined {
    return new SupportSessionRequestModel(this.object.get('request'));
  }

  async goal(): Promise<GoalModel | undefined> {
    const pointer = this.object.get('goal');
    if (!pointer) { return; }
    const goal = await pointer.fetch();
    return new GoalModel(goal);
  }

  bestKnownStartDate(): Date {
    return this.loggedStartDate() || this.scheduledStart();
  }

  bestKnownEndDate(): Date {
    return this.loggedEndDate() || this.scheduledEnd();
  }

  billAsScheduled(): boolean { return this.object.get('billAsScheduled') ?? false; }

  setBillAsScheduled(billAsScheduled: boolean): void { this.object.set('billAsScheduled', billAsScheduled); }

  createdAt(): Date | undefined {
    return this.object?.get('createdAt') ?? undefined;
  }
}
