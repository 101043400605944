/* eslint-disable @typescript-eslint/no-non-null-assertion */
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AustralianState, getAustralianStateAbbreviation, getAustralianStateDescription, ianaTimezoneForState } from '../../enums/AustralianState';
import { SupportNeedModelType } from '../../enums/SupportNeedModelType';
import { BrandColors, formatDateWithState } from '../../helpers';
import { getPostcodeClassification, getPostcodeClassificationName } from '../../helpers/postcodes';
import { PredictedSessionCost, SessionCost } from '../../managers/SessionCost';
import SessionsManager, { SupportSessionState, sessionStateFromSession } from '../../managers/Sessions';
import { GoalModel } from '../../models/Goal';
import { SessionStatus } from '../../models/Sessions';
import { SupportSessionModel } from '../../models/Sessions/SupportSession';
import { SupportNeedModel } from '../../models/SupportNeed';
import { SupportPlanModel } from '../../models/SupportPlan';
import { Postcode } from '../../types/parseConfig';
import { SaveButton } from '../../views/Client/SaveButton';
import { OverlappingAvatars } from '../OverlappingAvatars';
import { Selector } from '../Selector';
import { SessionNeedEditor } from '../SessionNeed/Editor';
import { CancelledSessionCostView } from './CancelledSessionCost';
import { EditableTimeLabelledValue } from './EditableLabelledValue';
import { GoalSummary } from './GoalSummary';
import { PredictedSessionCostView } from './PredictedSessionCost';
import { SessionCostView } from './SessionCost';
import { SimpleLabelledValue, SimpleLabelledValueAction } from './SimpleLabelledValue';

interface SupportSessionProps {
  session: SupportSessionModel;
  costs: {
    predicted: PredictedSessionCost | undefined;
    actual: SessionCost | undefined;
  };
  plans: SupportPlanModel[];
  plan: SupportPlanModel;
  need: SupportNeedModel | undefined;
  goal: GoalModel | undefined;
  disableInteraction: boolean;
  createdByUserName?: string;
  postcodes: Postcode[];
  isLoadingPricing: boolean;
  onSave: (state: SupportSessionState) => Promise<SupportSessionModel>;
  onSwitchToCancelState(): void;
  onChangePostcode(postcode: Postcode): void;
}

export const SupportSession: React.FC<SupportSessionProps> = ({
  session,
  costs,
  disableInteraction,
  plans,
  plan,
  need,
  goal,
  createdByUserName,
  postcodes,
  isLoadingPricing,
  onSave,
  onSwitchToCancelState,
  onChangePostcode,
}) => {
  const history = useHistory();
  const [sessionState, setSessionState] = useState<SupportSessionState>(sessionStateFromSession(session));
  const [planState, setPlanState] = useState<SupportPlanModel>(plan);
  const [isPlanOrNeedChanged, setIsPlanOrNeedChanged] = useState(false);
  const clientState = session.clientSync().australianState() ?? AustralianState.wa;
  const [selectedPostcode, setSelectedPostcode] = useState('');
  const [selectedPostcodeType, setSelectedPostcodeType] = useState('National');
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (planState === plan && need && need.id()) {
      setSessionState({ ...sessionState, needType: need.type() ?? SupportNeedModelType.Unknown });
      // setSessionState({ ...sessionState, needId: plan.supportNeedsSync().find(need => need.id() === session.supportNeedId())?.id() ?? '' });
    } else {
      setSessionState({ ...sessionState, needType: SupportNeedModelType.Unknown });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planState]);

  function handleChangePlan(plan: SupportPlanModel): void {
    setPlanState(plan);
    setIsPlanOrNeedChanged(true);
  }

  function handleChangeNeedType(needType?: SupportNeedModelType): void {
    setSessionState({ ...sessionState, needType: needType ?? SupportNeedModelType.Unknown });
    setIsPlanOrNeedChanged(true);
  }

  function handleChangePostcode(key: string): void {
    setSelectedPostcode(key);
    const split = key.split('__');
    const suburb = split[0];
    const postcode = split[1];
    setSessionState({
      ...sessionState,
      suburb,
      postcode,
    });
    const postcodeClassification = getPostcodeClassification(postcode, suburb, postcodes);
    setSelectedPostcodeType(getPostcodeClassificationName(postcodeClassification));
    onChangePostcode({
      postcode,
      suburb,
      classification: postcodeClassification,
    });
  }

  useEffect(() => {
    setSelectedPostcode(`${sessionState.suburb}__${sessionState.postcode}`);
    setSelectedPostcodeType(getPostcodeClassificationName(getPostcodeClassification(sessionState.postcode, sessionState.suburb, postcodes)));
  }, [sessionState]);

  // Show the cancel button, for any non-cancelled & no-show sessions
  const showCancel = ![
    SessionStatus.cancelledByClient,
    SessionStatus.cancelledByWorker,
    SessionStatus.cancelledByAdmin,
    SessionStatus.noShow,
  ].includes(session.status());

  return (
    <div className="m-4">
      <div className="row">
        <div
          className="col-1 mx-auto"
          style={{ marginBottom: -28 }}
        >
          <OverlappingAvatars
            bottomUrl={session.clientPhotoUrl()}
            topUrl={session.workerPhotoUrl()}
            size={80}
          />
        </div>
        <div className="col-12">
          <h2 className="font-weight-bold mb-0 text-center">{`${session.clientName()} with ${session.workerName()}`}</h2>
        </div>
      </div>
      {clientState && clientState !== AustralianState.wa &&
        <div className="d-flex justify-content-center mt-2">
          <span
            className="sm-pill badge badge-pill text-white text-uppercase ml-1"
            style={{ backgroundColor: BrandColors.darkBlue, fontSize: '1.0rem' }}
          >{getAustralianStateDescription(clientState)}
          </span>
        </div>
      }
      <hr />

      <div className="row">
        <SimpleLabelledValue
          title="ID"
          value={session.id()}
        />

        <SimpleLabelledValue
          title="Status"
          value={session.statusDescription()}
          action={!showCancel ? SimpleLabelledValueAction.EDIT : undefined}
          onClickAction={() => {
            onSwitchToCancelState();
          }}
        />

        <SimpleLabelledValue
          title="Date"
          value={formatDateWithState(session.scheduledStart(), 'dddd, Do MMMM, YYYY', session.clientSync().australianState())}
        />

        <SimpleLabelledValue
          title="Created by"
          value={createdByUserName ?? 'Unknown'}
        />

        <SimpleLabelledValue
          title="Requested"
          value={formatDateWithState(session.requestSync()?.createdAt() ?? '', 'dddd, Do MMMM, YYYY', session.clientSync().australianState())}
        />

        <SimpleLabelledValue
          title="Accepted"
          value={formatDateWithState(session.createdAt() ?? '', 'dddd, Do MMMM, YYYY', session.clientSync().australianState())}
        />

        {session.frequency() &&
          <div className="col-4 offset-md-2 pl-0">
            <p
              className="text-left font-weight-bold"
              style={{ width: 'fit-content' }}
            >{session.frequency()}</p>
          </div>

        }
        <div className="col-6" />
      </div>

      <hr />
      {(session.status() === SessionStatus.completed) &&
        <div className="row">
          <GoalSummary goal={goal} />
        </div>
      }
      {(session.status() === SessionStatus.completed) &&
        <hr />
      }


      <div className="row">

        <SimpleLabelledValue
          title={`Scheduled start ${clientState === AustralianState.wa ? '' : ` (${getAustralianStateAbbreviation(clientState)} Time)`}`}
          value={formatDateWithState(session.scheduledStart(), 'hh:mm A', session.clientSync().australianState())}
        />

        <SimpleLabelledValue
          title={`Scheduled end ${clientState === AustralianState.wa ? '' : ` (${getAustralianStateAbbreviation(clientState)} Time)`}`}
          value={formatDateWithState(session.scheduledEnd(), 'hh:mm A', session.clientSync().australianState())}
        />


        {session.cancelledDate() &&
          <SimpleLabelledValue
            title="Cancelled at"
            value={formatDateWithState(session.cancelledDate()!, 'dddd, Do MMMM, YYYY hh:mm A', session.clientSync().australianState())}
          />
        }

        {session.status() === SessionStatus.completed &&
          <>
            <EditableTimeLabelledValue
              title={`Logged start ${clientState === AustralianState.wa ? '' : ` (${getAustralianStateAbbreviation(clientState)} Time)`}`}
              value={sessionState.loggedStartDate}
              onChange={loggedStartDate => setSessionState({ ...sessionState, loggedStartDate })}
              ianaTimeZone={ianaTimezoneForState(clientState)}
            />
            <EditableTimeLabelledValue
              title={`Logged end ${clientState === AustralianState.wa ? '' : ` (${getAustralianStateAbbreviation(clientState)} Time)`}`}
              value={sessionState.loggedEndDate}
              onChange={loggedEndDate => setSessionState({ ...sessionState, loggedEndDate })}
              ianaTimeZone={ianaTimezoneForState(clientState)}

            />
            <SimpleLabelledValue
              title={`Finalised at\n${clientState === AustralianState.wa ? '' : ` (${getAustralianStateAbbreviation(clientState)} Time)`}`}
              value={session.finalisedAt() ? formatDateWithState(session.finalisedAt()!, 'dddd, Do MMMM, YYYY hh:mm A', session.clientSync().australianState()) : ''}
            />
          </>
        }
      </div>

      <hr />
      <div className="row">
        <>
          <label
            className="col-2 col-form-label text-right mb-1"
          >Location</label>
          <Selector
            options={[
              {
                name: '',
                key: '',
              },
            ].concat(postcodes.map((pc) => {
              return {
                name: `${pc.suburb.trim()} - ${pc.postcode.trim()}`,
                key: `${pc.suburb.trim()}__${pc.postcode.trim()}`,
              };
            }))}
            initial={selectedPostcode}
            onChange={e => { handleChangePostcode(e.target.value); }}
          />
        </>

        <SimpleLabelledValue
          title="Pricing"
          value={selectedPostcodeType}
        />
      </div>

      {(costs.actual || costs.predicted) &&
        <>
          <hr />
          {costs.actual && !session.isCancelledOrDeclined() &&
            <SessionCostView
              cost={costs.actual}
              state={sessionState}
              isLoadingPricing={isLoadingPricing}
              onStateChanged={state => setSessionState({ ...sessionState, ...state })}
            />
          }
          {costs.actual && session.isCancelledOrDeclined() &&
            <CancelledSessionCostView
              sessionStatus={session.rawParseObject().get('status')}
              cost={costs.actual}
              isLoadingPricing={isLoadingPricing}
            />
          }
          {costs.predicted && !session.isCancelledOrDeclined() &&
            <PredictedSessionCostView
              cost={costs.predicted}
              isLoadingPricing={isLoadingPricing}
            />
          }
        </>
      }

      <hr />


      <SessionNeedEditor
        isReadOnlyPlan={true}
        isReadOnlyNeed={false}
        allPlans={plans}
        sessionNeedType={need?.type()}
        selectedNeedType={sessionState.needType}
        sessionPlan={plan}
        selectedPlan={planState}
        onChangeNeedType={handleChangeNeedType}
        onChangePlan={handleChangePlan}
      />


      <hr />

      <div className="d-flex row justify-content-between">
        <div style={{ marginLeft: 214 }}>
          {
            showCancel && <button
              type="button"
              disabled={disableInteraction}
              className="btn btn-danger m-1"
              onClick={onSwitchToCancelState}
            >Cancel session</button>
          }
        </div>
        <div>
          <SaveButton
            onSave={async () => {
              setIsSaving(true);

              // Ensure the session length is at least 5 minutes, to catch edge cases where
              // it's possible for users to manually set an earlier end time than start time
              if (sessionState.loggedStartDate && sessionState.loggedEndDate) {
                const sessionLengthMins = moment(sessionState.loggedEndDate).diff(moment(sessionState.loggedStartDate), 'minutes');
                if (sessionLengthMins < 5) {
                  window.alert('Session length must be at least 5 minutes.');
                  return;
                }
              }
              
              const model = await onSave(sessionState);
              setSessionState(sessionStateFromSession(model));
              setIsSaving(false);

              // If the plan or need was edited, clear the dashboard sessions cache here
              if (isPlanOrNeedChanged) {
                SessionsManager.clearDashboardSessionsCache();
              }
            }}
            onCancel={() => {
              const modified = !_.isEqual(sessionStateFromSession(session), sessionState);
              if (modified) {
                if (window.confirm('You have unsaved changes, do you wish to continue?')) {
                  history.goBack();
                }
              } else {
                history.goBack();
              }
            }}
            cancelText="Back"

            disableCancel={disableInteraction}
            disableSave={disableInteraction || sessionState.needType === SupportNeedModelType.Unknown || _.isEqual(sessionStateFromSession(session), sessionState)}
            isSaving={isSaving}
          />
        </div>
      </div>
    </div>
  );
};